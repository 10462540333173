import React from 'react'
import { NavItemType } from './NavigationItem';
import Link from 'next/link';

export default function MegaMenuNavlink({
  item
}:{
  item:NavItemType
}) {
    return (
        <li key={item.id} className={`${item.isNew ? "menuIsNew" : ""}`}>
          <Link title={item.name} aria-label={item.name}
            className=" text-slate-600 hover:text-black dark:text-slate-400 dark:hover:text-white "
            href={{
              pathname: item.href || undefined,
            }}
          >
            {item.name}
          </Link>
        </li>
      );
}
