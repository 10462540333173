"use client";

import { FC, Fragment, ReactNode, useEffect, useState } from "react";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import { useParams, usePathname } from "next/navigation";
import { Transition,Dialog, Combobox } from "@headlessui/react";
import { useRouter } from "next13-progressbar";

interface Props {
  renderTrigger?: () => ReactNode;
}

const SearchModal: FC<Props> = ({ renderTrigger }) => {
  const params = useParams()
  const [open, setOpen] = useState(false);
  const [rawQuery, setRawQuery] = useState(decodeURIComponent(params.q as string??"")??""); 

const pathName = usePathname()
  

const router = useRouter()
useEffect(() => {
if (pathName.includes("/search")) {
  setOpen(false)
}
}, [router,pathName])


  return (
    <>
      <div onClick={() => setOpen(true)} className="cursor-pointer">
        {renderTrigger ? (
          renderTrigger()
        ) : (
          <button aria-label="Click to search" className="flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center">
            <svg
              width={22}
              height={22}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 22L20 20"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>

      <Transition.Root
        show={ open}
        as={Fragment}
        afterLeave={() => setRawQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-[99]"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/40 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
            >
              <Dialog.Panel
                className="block mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
             
              >
                <Combobox 
                    value={rawQuery}
                  name="searchpallet"
                >
                  <div className="relative">
           

                    <Combobox.Input onKeyDownCapture={(event)=> {
    if (event.key === 'Enter') {
      setOpen(false)
      router.push(`/search/${rawQuery}`)
      setRawQuery("")
    }
                    
                    }}
                    // @ts-ignore
                    value={rawQuery}
                      className="h-12 w-[95%] border-0 bg-transparent pl-5 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      onChange={(event) => setRawQuery(event.target.value)}
                    />
                    <div title={"search"} aria-label={"search"} onClick={()=> {
                      () => {setOpen(false)
                      router.push(`/search/${rawQuery}`)
                      setRawQuery("")
                    }
                    }} >
                    
                     <MagnifyingGlassIcon 
                      className=" cursor-pointer absolute top-3.5 right-4 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    </div>
                   </div>
                

                 

             
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SearchModal;
