import React, { useEffect, useState } from 'react'
import { NavItemType } from './NavigationItem';
import Link from 'next/link';
import { Post, PostsForCategoryMenuViewDocument, PostsForCategorySectionViewQuery, PostsForCategorySectionViewQueryVariables, PostStatus, SortOrder, usePostsForCategoryMenuViewLazyQuery, usePostsForCategorySectionViewLazyQuery, usePostsForCategorySectionViewQuery } from '@/graphql/generated/schema';
import NcImage from '../NcImage/NcImage';
import { getImage } from '@/config/getImage';
import { formatUrlForNews } from '@/utils/newsUrl';
import MainItem from './MainItem';
import MegaMenuNavlink from './MegaMenuNavlink';



export default  function  MegaMenu({
  menu,
  recentPosts
}:{
  menu:NavItemType, 
  recentPosts:Post[]
}) {

  




    if (!menu.children) {
        return null;
      }else{


        return (
          <li
            className={`menu-item flex-shrink-0 menu-megamenu menu-megamenu--large`}
          >
            <MainItem item={menu}/>
    
            <div className="invisible sub-menu absolute top-full inset-x-0 transform z-50">
              <div className="bg-white dark:bg-neutral-900 shadow-lg">
                <div className="container">
                  <div className="flex text-sm border-t border-slate-200 dark:border-slate-700 py-5">
                    <div className="   pr-8 overflow-y-auto">
                      {menu.children.map((item, index) => (
                        <div key={index} className="">
                          {/* <React.Fragment key={index}> */}
    
                          <Link title={item.name}  aria-label={item.name} href={item?.href} className="font-medium text-slate-900 dark:text-neutral-200">
                            {item.name}
                          </Link>
                          <ul className="flex flex-col space-y-3 mt-3">
                            {item.children?.map((i)=> <MegaMenuNavlink key={i.id}  item={i}/>)}
                          </ul>
                          {/* </React.Fragment> */}
                        </div>
                      ))}
                    </div>
                    <div className="w-[90%] border-l pl-5 ">
                      <div className="grid grid-cols-1 gap-10 sm:gap-8 lg:grid-cols-5">
                        <h3 className="sr-only">Recent posts</h3>
                
                        {/* <>
                        
                        <Block />
                        <Block />
                        <Block />
                        <Block />
                        <Block />
                        </>
                        : */}

                      {  recentPosts.map((post) => (
                          <article 
                            key={post.slug}
                            className="relative isolate flex flex-col  items-center lg:items-stretch"
                          >
                           
                            <div className="relative flex-none w-full sm:w-auto">
                              <NcImage
                                containerClassName="aspect-w-2 aspect-h-1 w-full rounded-xl bg-gray-100"
                                fill
                                className="rounded-xl object-cover"
                                src={getImage(post?.image?.url)}
                                alt=""
                              />
                              <div className="absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                            </div>
                            <div className="w-full">
                              <h4 className=" dark:text-slate-300 mt-2 text-sm font-semibold text-gray-900 line-clamp-2">
                                <Link title={post?.postitle} aria-label={post?.postitle} href={formatUrlForNews(post as Post)}>
                                  <span className="" />
                                  {post.postitle}
                                </Link>
                              </h4>
                            </div>
                          </article>
                        ))
                       }

                       
                      </div>
    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      }
}
