import React, { Fragment } from 'react'
import { Popover, Transition, PopoverButton, PopoverPanel } from "@/app/headlessui";
import MainItem from './MainItem';
import { NavItemType } from './NavigationItem';
import DropdownMenuNavlinkHasChild from './DropdownMenuNavlinkHasChild';
import DropdownMenuNavlink from './DropdownMenuNavlink';

export default function DropdownMenu({
    menuDropdown, menuCurrentHovers, onMouseEnterMenu, onMouseLeaveMenu
}:{
    menuCurrentHovers: string[], onMouseEnterMenu: (id: string) => void, onMouseLeaveMenu : (id: string) => void
    menuDropdown:NavItemType
}) {
    const isHover = menuCurrentHovers.includes(menuDropdown.id);
    return (
      <Popover
        as="li"
        className="menu-item menu-dropdown relative"
        onMouseEnter={() => onMouseEnterMenu(menuDropdown.id)}
        onMouseLeave={() => onMouseLeaveMenu(menuDropdown.id)}
      >
        {() => (
          <>
            <PopoverButton as={Fragment}>
              <MainItem item={menuDropdown}/>
            </PopoverButton>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel
                static
                className="sub-menu absolute transform z-10 w-56 top-full left-0"
              >
                <ul className="rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                  {menuDropdown.children?.map((i) => {
                    if (i.type) {
                      return <DropdownMenuNavlinkHasChild key={i.id} item={i} menuCurrentHovers={menuCurrentHovers} onMouseEnterMenu={onMouseEnterMenu} onMouseLeaveMenu={onMouseLeaveMenu}/>;
                    } else {
                      return (
                        <li
                          key={i.id}
                          className={`px-2 ${i.isNew ? "menuIsNew" : ""}`}
                        >
                          <DropdownMenuNavlink item={i}/>
                          </li>
                      );
                    }
                  })}
                </ul>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    );
}
