'use client'
import { Next13ProgressBar } from 'next13-progressbar';
import { Suspense } from 'react';

export default function ProgressBar() {
  return (
    <>
{/* <Suspense> */}

              <Next13ProgressBar options={{ showSpinner: false }} showOnShallow />
{/* </Suspense> */}
   

    </>
  )
}
