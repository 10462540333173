import { Popover, Transition, PopoverButton, PopoverPanel } from "@/app/headlessui";
import { NavItemType } from "./NavigationItem";
import { Fragment } from "react";
import DropdownMenuNavlink from "./DropdownMenuNavlink";

const DropdownMenuNavlinkHasChild = ({item , menuCurrentHovers, onMouseEnterMenu, onMouseLeaveMenu }:{
    item:NavItemType , menuCurrentHovers: string[], onMouseEnterMenu: (id: string) => void, onMouseLeaveMenu : (id: string) => void
}) => {
    const isHover = menuCurrentHovers.includes(item.id);
    return (
      <Popover
        as="li"
        key={item.id}
        className="menu-item menu-dropdown relative px-2"
        onMouseEnter={() => onMouseEnterMenu(item.id)}
        onMouseLeave={() => onMouseLeaveMenu(item.id)}
      >
        {() => (
          <>
            <PopoverButton as={Fragment}>
              <DropdownMenuNavlink item={item}/>

            </PopoverButton>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel
                static
                className="sub-menu absolute z-10 w-56 left-full pl-2 top-0"
              >
                <ul className="rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                  {item.children?.map((i) => {
                    if (i.type) {
                      return <DropdownMenuNavlinkHasChild item={i} key={i.id}  menuCurrentHovers={menuCurrentHovers} onMouseEnterMenu={onMouseEnterMenu} onMouseLeaveMenu={onMouseLeaveMenu}/>
                    } else {
                      return (
                        <li key={i.id} className="px-2">
                          <DropdownMenuNavlink item={i}/>

                        </li>
                      );
                    }
                  })}
                </ul>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  export default DropdownMenuNavlinkHasChild