import React from 'react'
import { NavItemType } from './NavigationItem';
import Link from 'next/link';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export default function DropdownMenuNavlink({
    item
}:{
    item:NavItemType
}) {
    return (
        <Link
          className="flex items-center font-normal text-neutral-6000 dark:text-neutral-400 py-2 px-4 rounded-md hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"
          href={{
            pathname: item.href || undefined,
          }}
        >
          {item.name}
          {item.type && (
            <ChevronDownIcon
              className="ms-2 h-4 w-4 text-neutral-500"
              aria-hidden="true"
            />
          )}
        </Link>
      );
}
