import Image from "next/image";
import React from "react";
import logo from '@/images/thehawk_logo.png'
const LogoSvg = () => {
  return (
<Image src={logo} height={200} width={200} alt="logo" loading="eager"/>
  );
};

export default LogoSvg;
