// @ts-nocheck

"use client";

import { Popover, Transition, PopoverButton, PopoverPanel } from "@/app/headlessui";
import { avatarImgs } from "@/contains/fakeData";
import { Fragment } from "react";
import Avatar from "@/components/Avatar/Avatar";
import SwitchDarkMode2 from "@/components/SwitchDarkMode/SwitchDarkMode2";
import Link from "next/link";
import { useLogoutMutation } from "@/graphql/generated/schema";
import Spin from "antd/es/spin";

import ContentLoader from "react-content-loader";
import { useSession } from 'next-auth/react';
import { useRouter } from "next13-progressbar";

export default function AvatarDropdown() {
  const { data } = useSession();

  const router = useRouter()
  // const dispatch = useAppDispatch()
  const [LogOutMutation] = useLogoutMutation()
  const Onlogout = async () => {
    // dispatch(logout())
    await LogOutMutation();
    // await client.clearStore()
    window.location.href = "/"
  }

  return (
    <div className="AvatarDropdown ">

      {
        // !data?.id?
        <button title="My Account" aria-label="View my account"  className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}
          onClick={() => {
            if (!data?.id) {
              router.push('/login')

            } else {
              router.push('/dashboard')

            }

          }}
          >
          <svg
            className=" w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

        </button>
        //  :
        // <Popover className="relative" con>
        //   {({ open, close }) => (
        //     <>
        //       <PopoverButtonWithRef
        //         className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}
        //       >
        //         <svg
        //           className=" w-6 h-6"
        //           viewBox="0 0 24 24"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //             d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        //             stroke="currentColor"
        //             strokeWidth="1.5"
        //             strokeLinecap="round"
        //             strokeLinejoin="round"
        //           />
        //           <path
        //             d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
        //             stroke="currentColor"
        //             strokeWidth="1.5"
        //             strokeLinecap="round"
        //             strokeLinejoin="round"
        //           />
        //         </svg>
        //       </PopoverButtonWithRef>
        //       <Transition
        //         as={Fragment}
        //         enter="transition ease-out duration-200"
        //         enterFrom="opacity-0 translate-y-1"
        //         enterTo="opacity-100 translate-y-0"
        //         leave="transition ease-in duration-150"
        //         leaveFrom="opacity-100 translate-y-0"
        //         leaveTo="opacity-0 translate-y-1"
        //       >

        //       </Transition>
        //     </>
        //   )}
        // </Popover>
      }
    </div>
  );
}
