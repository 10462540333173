"use client"
import React, { FC, Suspense, useState } from "react";
import Logo from "@/components/Logo/Logo";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "@/components/Navigation/Navigation";

import { NavItemType } from "../Navigation/NavigationItem";
// import { auth } from "@/app/auth";

import SearchModal from "./SearchModal";
import NotifyDropdown from "./NotifyDropdown";
import MenuOpenButton from "./MenuOpenButton";
import MenuBar from "../MenuBar/MenuBar";
import { Post } from "@/graphql/generated/schema";

export interface MainNav2LoggedProps {
  menus: NavItemType[]
  hamburger: NavItemType[]
  recentPostsOfMenu: { 
    [key:string]: Post[]
  }
}

const MainNav2Logged: FC<MainNav2LoggedProps> = ({ menus , hamburger , recentPostsOfMenu }) => {
  // const session = await auth()
  const [isVisable, setIsVisable] = useState(false);

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between">

        <div className={"flex items-center  xs:max-lg:flex-1 lg:max-2xl:flex-[unset] "}>
          {/* @ts-ignore */}
          <MenuBar  menus={menus} hamburger={hamburger} setIsVisable={setIsVisable} isVisable={isVisable}/>
        </div>

        <div className="lg:flex-1 flex items-center">
          <Logo />
        </div>

        <div className="flex-[2] hidden lg:flex justify-center mx-4">
          <Navigation menus={menus} recentPostsOfMenu={recentPostsOfMenu} />
        </div>

        <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
          <SearchModal />
          {/* {
            session?.user?.user?.id?
            <NotifyDropdown />:null
          } */}
          <AvatarDropdown />
      <MenuOpenButton             onClick={() => {
              setIsVisable(!isVisable);
            }}
/>
        </div>
      </div>
    );
  };

  return (
    

    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="md:container xs:max-md:mx-2">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;

