"use client";

import dynamic from "next/dynamic";

const Transition = dynamic(() => import("@headlessui/react").then(module => module.Transition), {
  ssr: false
});
const TransitionRoot = dynamic(() => import("@headlessui/react").then(module => module.Transition.Root), {
  ssr: false
});
const TransitionChild = dynamic(() => import("@headlessui/react").then(module => module.Transition.Child), {
  ssr: false
});
const Dialog = dynamic(() => import("@headlessui/react").then(module => module.Dialog), {
  ssr: false
});
const DialogOverlay = dynamic(() => import("@headlessui/react").then(module => module.Dialog.Overlay), {
  ssr: false
});
const DialogTitle = dynamic(() => import("@headlessui/react").then(module => module.Dialog.Title), {
  ssr: false
});
const DialogPanel = dynamic(() => import("@headlessui/react").then(module => module.Dialog.Panel), {
  ssr: false
});
const Disclosure = dynamic(() => import("@headlessui/react").then(module => module.Disclosure), {
  ssr: false
});
const DisclosureButton = dynamic(() => import("@headlessui/react").then(module => module.Disclosure.Button), {
  ssr: false
});
const DisclosurePanel = dynamic(() => import("@headlessui/react").then(module => module.Disclosure.Panel), {
  ssr: false
});
const Switch = dynamic(() => import("@headlessui/react").then(module => module.Switch), {
  ssr: false
});
const PopoverButton = dynamic(() => import("@headlessui/react").then(module => module.Popover.Button), {
  ssr: false
});
const PopoverPanel = dynamic(() => import("@headlessui/react").then(module => module.Popover.Panel), {
  ssr: false
});
const Popover = dynamic(() => import("@headlessui/react").then(module => module.Popover), {
  ssr: false
});

const RadioGroup = dynamic(() => import("@headlessui/react").then(module => module.RadioGroup), {
  ssr: false
});
const RadioGroupLabel = dynamic(() => import("@headlessui/react").then(module => module.RadioGroup.Label), {
  ssr: false
});
const RadioGroupOption = dynamic(() => import("@headlessui/react").then(module => module.RadioGroup.Option), {
  ssr: false
});

const Tab = dynamic(() => import("@headlessui/react").then(module => module.Tab), {
  ssr: false
});
const TabGroup = dynamic(() => import("@headlessui/react").then(module => module.Tab), {
  ssr: false
});
const TabList = dynamic(() => import("@headlessui/react").then(module => module.Tab), {
  ssr: false
});
const TabPanels = dynamic(() => import("@headlessui/react").then(module => module.Tab), {
  ssr: false
});
const TabPanel = dynamic(() => import("@headlessui/react").then(module => module.Tab), {
  ssr: false
});
const Combobox = dynamic(() => import("@headlessui/react").then(module => module.Combobox), {
  ssr: false
});
const ComboboxInput = dynamic(() => import("@headlessui/react").then(module => module.Combobox.Input), {
  ssr: false
});
const Listbox = dynamic(() => import("@headlessui/react").then(module => module.Listbox), {
  ssr: false
});
const ListboxButton = dynamic(() => import("@headlessui/react").then(module => module.Listbox.Button), {
  ssr: false
});
const ListboxOptions = dynamic(() => import("@headlessui/react").then(module => module.Listbox.Options), {
  ssr: false
});
const ListboxOption = dynamic(() => import("@headlessui/react").then(module => module.Listbox.Option), {
  ssr: false
});
const MenuButton = dynamic(() => import("@headlessui/react").then(module => module.Menu.Button), {
  ssr: false
});
const MenuItems = dynamic(() => import("@headlessui/react").then(module => module.Menu.Items), {
  ssr: false
});
const MenuItem = dynamic(() => import("@headlessui/react").then(module => module.Menu.Item), {
  ssr: false
});
const Menu = dynamic(() => import("@headlessui/react").then(module => module.Menu), {
  ssr: false
});
const Portal = dynamic(() => import("@headlessui/react").then(module => module.Portal), {
  ssr: false
});
const FocusTrap = dynamic(() => import("@headlessui/react").then(module => module.FocusTrap), {
  ssr: false
});

export {
  Transition,
  Dialog,
  Disclosure,
  Switch,
  Popover,
  RadioGroup,
  Tab,
  Combobox,
  Listbox,
  Menu,
  Portal,
  FocusTrap,
  TransitionChild,
  TransitionRoot,
  DialogPanel,
  ComboboxInput,
  DisclosureButton,
  DisclosurePanel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  PopoverButton,
  PopoverPanel,
  TabGroup,
  TabList,
  TabPanels,
  TabPanel,
  RadioGroupLabel,
  RadioGroupOption,
  DialogOverlay,
  DialogTitle,
  MenuButton,
  MenuItems,
  MenuItem
}
