import { IMAGE_URL } from "./secrets"
import logo from '@/images/logo.svg'

export const getImage = (file?: string) => {

    if (file) {

        if (file.includes("https")) {
            return file
        } else {

            return `${IMAGE_URL}/${file}`
        }
    } else {
        return logo
    }

}