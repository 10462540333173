import Link from 'next/link';
import React from 'react'
import { NavItemType } from './NavigationItem';
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export default function MainItem({
item
}:{
    item:NavItemType
}) {
    return (
        <div className="h-20 flex-shrink-0 flex items-center">
          <Link title={item.name} aria-label={item.name}
            className="inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200"
            href={{
              pathname: item.href || undefined,
            }}
          >
            {item.name}
            {item.type && (
              <ChevronDownIcon
                className="ms-1 -me-1 h-4 w-4 text-slate-400"
                aria-hidden="true"
              />
            )}
          </Link>
        </div>
      );
}
