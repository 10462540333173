"use client"
import React from "react";
import { CustomLink } from "@/data/types";

import CategorySection from "./CategorySection";
import InfoArea from "./InfoArea";
import { useParams, usePathname } from "next/navigation";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  const pathhName = usePathname();
const params = useParams()
  return (
    <>
      {/* footer */}

      <div className="nc-Footer relative  border-t border-neutral-200 dark:border-neutral-700 ">
        <div
          className={`dark:bg-neutral-900 bg-white ${
            pathhName.includes("/news") && params?.category
              ? "z-[9999999999999999999999999999999999999]"
              : ""
          }   w-full absolute`}
        >
          <InfoArea />
          <div className="lg:container xs:max-lg:mx-5 py-5  ">
            <CategorySection />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
