import React from "react";
import logoImg from "@/images/logo.png";
import logoLightImg from "@/images/logo-light.png";
import LogoSvg from "./LogoSvg";
import Link from "next/link";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
}) => {
  return (
    <div>

    <Link title={"Logo"} aria-label={"Logo"}
      href="/"
      className=" block  w-[200px]"
    >

      <LogoSvg />
    </Link>
    </div>
  );
};

export default Logo;
