import React, { FC, } from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";
import { Post } from "@/graphql/generated/schema";





interface Props {
  className?: string;
  menus: NavItemType[]
  recentPostsOfMenu: { 
    [key:string]: Post[]
  }
}

const Navigation: FC<Props> = ({ className = "flex", menus, recentPostsOfMenu }) => {



  
  

  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {menus.map((item,) => (
            //  @ts-ignore

        <NavigationItem key={item.id} menuItem={item as NavItemType} recentPosts={recentPostsOfMenu[item.categoryId]}
        // recentPosts={recntPostsOfMenu[item.categoryId]}
         />
      ))}
    </ul>
  );
};

export default Navigation;
