import React from 'react'

import Logo from '../Logo/Logo'
import SocialsList from '../SocialsList/SocialsList'
import Link from 'next/link'

export default function InfoArea() {
  return (
    <div className=" py-5">
            <div className="relative lg:container xs:max-lg:mx-5 border-b dark:border-b-neutral-700 pb-3">
    <div className="flex justify-between lg:flex-row xs:max-lg:flex-col">
    <div className="flex  flex-col xs:max-lg:justify-center ">
      <Logo />
      <div className="flex justify-between items-center mt-4">

      <SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />
      </div>
  

    </div>
    <div className="flex flex-col">
    <div className="flex flex-row flex-wrap justify-between lg:space-x-8 xs:max-lg:mt-3 ">
        <Link  className='mb-2' href={'/privacy-policy'}>
        Privacy Policy
        </Link>
        <Link  className='mb-2' href={'/contact-us'}>
        Contact Us
        </Link>
        <a target='_blank' href={'https://epaper.thehawk.in/?_gl=1*l6yldo*_ga*MTcxMzE4OTIzMi4xNzI3ODU4Njc4*_ga_MZFQ2G6NCB*MTcyNzk2NjYyNC44LjEuMTcyNzk3MDc3MC4wLjAuMTY0MDA1OTE4NQ'}>
        Epaper
        </a>
        <Link  className='mb-2' href={'/news/subscribe'}>
        Newsletters
        </Link>
        <Link  className='mb-2' href={'/sitemap.xml'}>
        Sitemap
        </Link>
      </div>

    </div>
  </div>
  </div></div>
  )
}
