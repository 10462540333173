"use client"


import React, { FC,  useState } from "react";
import { Route } from "@/routers/types";


import MainItem from "./MainItem";

import DropdownMenu from "./DropdownMenu";
import MegaMenu from "./MegaMenu";
import { Post } from "@/graphql/generated/schema";

export interface NavItemType {
  id: string;
  name: string;
  href: Route;
  targetBlank?: boolean;
  children?: NavItemType[];
  type?: "dropdown" | "megaMenu" | "none";
  isNew?: boolean;
  categoryId?: string
}

export interface NavigationItemProps {
  menuItem: NavItemType;
  recentPosts:Post[]
}



const NavigationItem: FC<NavigationItemProps> = ({ menuItem , recentPosts}) => {

  const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);

  const onMouseEnterMenu = (id: string) => {
    setMenuCurrentHovers((state) => [...state, id]);
  };

  const onMouseLeaveMenu = (id: string) => {
    setMenuCurrentHovers((state) => {
      return state.filter((item, index) => {
        return item !== id && index < state.indexOf(id);
      });
    });
  };













  switch (menuItem.type) {
    case "dropdown":
      return <DropdownMenu menuDropdown={menuItem} menuCurrentHovers={menuCurrentHovers} onMouseEnterMenu={onMouseEnterMenu} onMouseLeaveMenu={onMouseLeaveMenu} />;
    case "megaMenu":
      return <MegaMenu menu={menuItem}
       recentPosts={recentPosts}
      />;
    default:
      return (
        <li className="menu-item flex-shrink-0">
          <MainItem item={menuItem}/>
        
        </li>
      );
  }
};

export default NavigationItem;
