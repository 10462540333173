import { Post } from "@/graphql/generated/schema";
import { Route } from "next";

export const formatUrlForNews = (post: Post): Route => {
      if (post?.category?.slug && post?.subcategory?.slug) {
            return `/news/${post?.category?.slug}/${post.subcategory.slug}/${post.slug}` as Route
      } else {

            return `/news/${post?.category?.slug}/${post.slug}` as Route
      }
}